import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Link } from "react-router-dom";
import ServiceType from "../../enums/serviceType";

const CTAButton: React.FC<{
  serviceType?: keyof typeof ServiceType;
}> = ({ serviceType }) => {
  const classes = useStyles();

  return (
    <Link
      to={serviceType ? `/book-now?service=${serviceType}` : "/book-now"}
      className={classes.ctaButton}
    >
      Book Now
    </Link>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  ctaButton: {
    display: "block",
    width: "max-content",
    margin: "20px auto",
    padding: "10px 30px",
    fontSize: "1.1rem",
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.contrastText}`,
    cursor: "pointer",
    borderRadius: "5px",
    transition: "background-color 0.3s ease",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export default CTAButton;
