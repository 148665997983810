import React, { useEffect, useState } from "react";

// Import necessary components and styles
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material";
import { Page } from "../components/skeleton";
import { Loader } from "../components/loader";
import HeroBanner from "../components/heroBanner/HeroBanner";
import { ApplicationConstants } from "../constants";
import Gallery from "../components/gallery/Gallery";
import { ReactImageGalleryItem } from "react-image-gallery";
import CTAButton from "../components/ctaButton/CTAButton";

const GalleryPage: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    // Set the document title
    document.title = `${ApplicationConstants.CompanyName} - Gallery`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page>
      {isLoading && <Loader position="top-center" type="inline" />}
      {!isLoading && (
        <main>
          <section id="gallery" className={classes.section}>
            <h2 className={classes.sectionTitle}>Gallery</h2>
            <CTAButton />
            <Gallery />
          </section>
        </main>
      )}
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    backgroundColor: "#004a99",
    color: "#fff",
    padding: "25px",
    borderRadius: "8px",
    margin: "30px 0",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    "& p": {
      fontSize: "1rem",
      lineHeight: "1.6",
      marginBottom: "15px",
    },
  },
  sectionTitle: {
    textAlign: "center",
    fontSize: "2.2rem",
    marginBottom: "20px",
    color: theme.palette.primary.contrastText,
  },
}));

export default GalleryPage;
