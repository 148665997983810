import React, { useEffect, useState } from "react";

// Import necessary components and styles
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material";
import ServiceType, {
  ServiceTypeDescriptions,
  ServiceTypeImages,
} from "../enums/serviceType";
import { Page } from "../components/skeleton";
import { Loader } from "../components/loader";
import HeroBanner from "../components/heroBanner/HeroBanner";
import CTAButton from "../components/ctaButton/CTAButton";
import { ApplicationConstants } from "../constants";
import Gallery from "../components/gallery/Gallery";
import { ReactImageGalleryItem } from "react-image-gallery";

const HomePage: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    // Set the document title
    document.title = `${ApplicationConstants.CompanyName}`;
    window.scrollTo(0, 0);
  }, []);

  // Component - Service Links
  const ServiceLinks: React.FC<{
    serviceType: keyof typeof ServiceType;
  }> = ({ serviceType }) => {
    return (
      <>
        <CTAButton serviceType={serviceType} />
        <div className={classes.buttonContainer}>
          <Link
            to={`/pricing?service=${serviceType}`}
            className={classes.viewPricingBtn}
          >
            View Pricing
          </Link>
        </div>
      </>
    );
  };

  //  Component - Service Section
  const ServiceSection: React.FC<{
    serviceType: keyof typeof ServiceType;
  }> = ({ serviceType }) => {
    return (
      <div className={classes.serviceRow}>
        <div className={classes.serviceText}>
          <h3>{ServiceType[serviceType]}</h3>
          <p>{ServiceTypeDescriptions[serviceType]}</p>
          <ServiceLinks serviceType={serviceType} />
        </div>
        <div className={classes.serviceImage}>
          <img src={ServiceTypeImages[serviceType]} alt={serviceType} />
        </div>
      </div>
    );
  };

  const images: ReactImageGalleryItem[] = [
    {
      original: "../../images/gallery/gallery (1).webp",
      thumbnail: "../../images/gallery/thumb/gallery (1)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (2).webp",
      thumbnail: "../../images/gallery/thumb/gallery (2)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (3).webp",
      thumbnail: "../../images/gallery/thumb/gallery (3)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (4).webp",
      thumbnail: "../../images/gallery/thumb/gallery (4)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (5).webp",
      thumbnail: "../../images/gallery/thumb/gallery (5)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (6).webp",
      thumbnail: "../../images/gallery/thumb/gallery (6)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (7).webp",
      thumbnail: "../../images/gallery/thumb/gallery (7)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (8).webp",
      thumbnail: "../../images/gallery/thumb/gallery (8)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (9).webp",
      thumbnail: "../../images/gallery/thumb/gallery (9)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (10).webp",
      thumbnail: "../../images/gallery/thumb/gallery (10)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (11).webp",
      thumbnail: "../../images/gallery/thumb/gallery (11)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (12).webp",
      thumbnail: "../../images/gallery/thumb/gallery (12)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (13).webp",
      thumbnail: "../../images/gallery/thumb/gallery (13)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (14).webp",
      thumbnail: "../../images/gallery/thumb/gallery (14)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (15).webp",
      thumbnail: "../../images/gallery/thumb/gallery (15)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (16).webp",
      thumbnail: "../../images/gallery/thumb/gallery (16)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (17).webp",
      thumbnail: "../../images/gallery/thumb/gallery (17)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (18).webp",
      thumbnail: "../../images/gallery/thumb/gallery (18)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (19).webp",
      thumbnail: "../../images/gallery/thumb/gallery (19)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (20).webp",
      thumbnail: "../../images/gallery/thumb/gallery (20)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (21).webp",
      thumbnail: "../../images/gallery/thumb/gallery (21)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (22).webp",
      thumbnail: "../../images/gallery/thumb/gallery (22)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (23).webp",
      thumbnail: "../../images/gallery/thumb/gallery (23)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (24).webp",
      thumbnail: "../../images/gallery/thumb/gallery (24)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (25).webp",
      thumbnail: "../../images/gallery/thumb/gallery (25)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (26).webp",
      thumbnail: "../../images/gallery/thumb/gallery (26)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (27).webp",
      thumbnail: "../../images/gallery/thumb/gallery (27)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (28).webp",
      thumbnail: "../../images/gallery/thumb/gallery (28)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (29).webp",
      thumbnail: "../../images/gallery/thumb/gallery (29)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (30).webp",
      thumbnail: "../../images/gallery/thumb/gallery (30)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (31).webp",
      thumbnail: "../../images/gallery/thumb/gallery (31)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (32).webp",
      thumbnail: "../../images/gallery/thumb/gallery (32)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (33).webp",
      thumbnail: "../../images/gallery/thumb/gallery (33)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (34).webp",
      thumbnail: "../../images/gallery/thumb/gallery (34)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (35).webp",
      thumbnail: "../../images/gallery/thumb/gallery (35)-thumb.webp",
    },
    {
      original: "../../images/gallery/gallery (36).webp",
      thumbnail: "../../images/gallery/thumb/gallery (36)-thumb.webp",
    },
  ];

  return (
    <Page>
      {isLoading && <Loader position="top-center" type="inline" />}
      {!isLoading && (
        <main>
          <HeroBanner />

          <section id="gallery" className={classes.gallerySection}>
            <Gallery items={images} />
          </section>

          <section id="services" className={classes.servicesSection}>
            <h2 className={classes.sectionTitle}>
              Our Premium Mobile Detailing Services
            </h2>

            {Object.keys(ServiceType).map((key) => (
              <ServiceSection
                key={key}
                serviceType={key as keyof typeof ServiceType}
              />
            ))}
          </section>

          <section id="about" className={classes.aboutSection}>
            <h2 className={classes.sectionTitle}>About Jamin Detail</h2>
            <p>
              At Jamin Detail, we're passionate about bringing unparalleled
              mobile detailing services directly to you. Our mission is to offer
              the convenience of premium car care without the need to leave your
              home or office. With a commitment to excellence and a keen eye for
              detail, our expert team uses only the finest products and
              specialized equipment to ensure your vehicle not only shines but
              is protected for the journey ahead.
            </p>
            <p>
              From the thorough cleaning of interiors that rejuvenates your
              car's cabin to the meticulous exterior detailing that restores its
              showroom shine, we cover every aspect of vehicle care. Our
              services extend beyond just cleaning; we provide paint correction,
              protection, and a range of specialized treatments designed to
              enhance and preserve the beauty of your vehicle.
            </p>
            <p>
              Understanding the unique needs of each client and vehicle, we
              offer customized solutions that cater to your specific
              requirements. Whether it's a family car that needs a fresh feel, a
              luxury vehicle demanding specialized attention, or a boat that
              seeks marine detailing, Jamin Detail stands ready to serve with
              professionalism and unparalleled skill.
            </p>
            <p>
              Driven by a passion for automotive care and a commitment to
              customer satisfaction, we invite you to experience the Jamin
              Detail difference. Let us take care of your vehicle, so you can
              take pride in its appearance and performance, no matter where your
              travels take you.
            </p>
          </section>

          <section id="contact" className={classes.contactSection}>
            <h2 className={classes.sectionTitle}>Contact Us</h2>
            <p>Stay connected with us for updates and inquiries:</p>
            <p>
              Phone: <a href="tel:+13525140964">(352) 514-0964</a>
            </p>
            <p>
              Email:
              <a href="mailto:jamindetail22@gmail.com?subject=Booking%20Information%20Request">
                jamindetail22@gmail.com
              </a>
            </p>
            <p>
              <a
                href="https://www.facebook.com/profile.php?id=100086496340257"
                target="_blank"
              >
                Facebook
              </a>
            </p>
          </section>
        </main>
      )}
    </Page>
  );
};

// /* Responsive adjustments for smaller screens */
// @media (max-width: 1100px) {
//   .service-row {
//     flex-direction: column; /* Stacks the text and image blocks vertically on smaller screens */
//     text-align: center; /* Centers the text for a better mobile layout */
//   }

//   .service-row:nth-child(even) .service-text {
//     order: 0;
//   }
// }

const useStyles = makeStyles((theme: Theme) => ({
  gallerySection: {
    scrollPaddingTop: "150px",
    // maxWidth: "500px",
    // maxHeight: "500px",
    margin: "0 auto",
    padding: "20px 20px",
  },
  servicesSection: {
    // marginBottom: "20px",
    scrollPaddingTop: "150px",
    maxWidth: "2200px",
    margin: "0 auto",
    padding: "0px 20px",
  },
  sectionTitle: {
    textAlign: "center",
    fontSize: "2.2rem",
    marginBottom: "40px",
    color: theme.palette.primary.main,
  },
  serviceRow: {
    display: "flex",
    alignItems: "stretch",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginBottom: "30px",
    borderRadius: "8px",
    overflow: "hidden",

    /* This rule will switch the order for the text block in every even row */
    "&:nth-child(even)": {
      flexDirection: "row-reverse",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      textAlign: "center",
      // Removes the order change on smaller screens for a consistent top-to-bottom flow
      "&:nth-child(even)": {
        flexDirection: "column",
      },
    },
  },
  serviceText: {
    flexBasis: "50%",
    padding: "25px",

    "& h3": {
      textAlign: "center",
      fontSize: "1.8rem",
      color: theme.palette.primary.contrastText,
      marginBottom: "15px",
      textDecoration: "underline",
      paddingBottom: "0px",
    },
    "& p": {
      fontSize: "1rem",
      lineHeight: "1.6",
    },
    [theme.breakpoints.down("md")]: {
      flexBasis: "100%",
      padding: "15px 0",
    },
  },
  buttonContainer: {
    textAlign: "center",
    marginBottom: "10px",
  },
  viewPricingBtn: {
    display: "inline-block",
    padding: "8px 20px",
    color: theme.palette.common.white,
    backgroundColor: "transparent",
    borderRadius: "5px",
    fontWeight: "normal",
    transition: "background-color 0.3s, color 0.3s",
    "&:hover": {
      color: "#ffffff",
    },
  },

  serviceImage: {
    flexBasis: "50%",
    padding: "0px",
    display: "flex",
    alignItems: "stretch",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    [theme.breakpoints.down("md")]: {
      flexBasis: "100%",
      // padding: "0 0",
    },
  },

  aboutSection: {
    backgroundColor: "#004a99",
    color: "#fff",
    padding: "25px",
    borderRadius: "8px",
    margin: "30px 0",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    "& h2": {
      color: "#fff",
      textAlign: "center",
      marginBottom: "20px",
      fontSize: "2.2rem",
    },
    "& p": {
      fontSize: "1rem",
      lineHeight: "1.6",
      marginBottom: "15px",
    },
  },
  contactSection: {
    // body.dark-mode #contact h2 {
    //   color: #004a99; /* A brighter blue for headings to stand out against the dark background */
    // }
    // body.dark-mode #contact p {
    //   color: #ddd; /* Lighter grey for text and links, ensuring readability */
    // }
    // body.dark-mode #contact a:hover {
    //   color: #ccc; /* Slightly lighter grey on hover for an interactive effect */
    //   text-decoration: underline; /* Underline on hover for better user interaction */
    // }

    padding: "20px",
    backgroundColor: "#f4f4f4",
    borderRadius: "8px",
    margin: "20px 0",
    "& h2": {
      color: "#004a99",
      marginBottom: "15px",
    },
    "& p": {
      color: "#333",
      marginBottom: "10px",
    },
    "& a": {
      color: "#004a99",
      textDecoration: "none",
    },
    "& a:hover": {
      textDecoration: "underline",
    },
  },
}));

// TODO: Implement
// /* Dark Mode Text Color Adjustments */
// body.dark-mode .service-text,
// body.dark-mode .pricing-link,
// body.dark-mode .view-pricing-btn {
//   color: #e0e0e0; /* Lighter text color for readability in dark mode */
// }

// /* Adjusting 'View Pricing' Button for Dark Mode */
// body.dark-mode .view-pricing-btn {
//   border-color: #e0e0e0; /* Lighter border color for visibility in dark mode */
// }

// /* Dark mode specific styles */
// body.dark-mode select {
//   background-color: #555; /* Darker background for the dropdown */
//   color: #ddd; /* Lighter text color for better readability */
//   border-color: #444; /* Darker border to fit the dark theme */
// }

// body.dark-mode .view-pricing-btn:hover {
//   background-color: #e0e0e0; /* Lighter background on hover for contrast */
//   color: #333; /* Dark text color for readability on the light background */
// }

export default HomePage;
