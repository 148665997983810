import React, { FC } from "react";
import clsx from "clsx";
import { Theme, alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
// components
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
// types
import { CircularProgressProps } from "@mui/material/CircularProgress";

type LoaderPosition = "centered" | "left" | "top-center" | "right";
type LoaderSize = "small" | "medium" | "large";
type LoaderType = "fullscreen" | "inline" | "overlay";
type LoaderTopOffset = number;

interface ILoaderExtendedProps extends CircularProgressProps {
  children?: React.ReactNode;
  loaderWrapperClassName?: string;
  /** 'centered' | 'left' | 'top-center' | 'right'  */
  position?: LoaderPosition;
  /** number */
  topOffset?: LoaderTopOffset;
  /** 'small' | 'medium' | 'large' */
  size?: LoaderSize;
  subtitle?: string;
  title?: string;
  /** 'fullscreen' | 'inline' | 'overlay' */
  type?: LoaderType;
}

export type ILoaderProps = Omit<ILoaderExtendedProps, "variant">;

export const Loader: FC<ILoaderProps> = ({
  children,
  loaderWrapperClassName,
  subtitle,
  title = "Loading...",
  ...props
}) => {
  const classes = useStyles(props);
  return (
    <div
      className={clsx(classes.loader, loaderWrapperClassName || "")}
      data-testid={props.id}
      role="alert"
    >
      <div className={classes.inner}>
        <CircularProgress
          {...props}
          className={clsx(classes.progress, props.className)}
          size={props.size === "large" ? 18 : props.size === "small" ? 10 : 14}
          variant="indeterminate"
        />
        <div className={classes.text}>
          {children}
          {!children && (
            <>
              <Typography className={classes.title} variant="body1">
                {title}
              </Typography>
              {subtitle && (
                <Typography className={classes.subtitle} variant="subtitle1">
                  {subtitle}
                </Typography>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles<Theme, ILoaderProps>((theme: Theme) => {
  return createStyles({
    inner: {
      alignItems: "center",
      display: "flex",
      flexShrink: 1,
      flexWrap: "wrap",
      justifyContent: (props) => {
        switch (props.position) {
          case "top-center":
            return "center";
          case "centered":
            return "center";
          case "left":
            return "flex-start";
          case "right":
            return "flex-end";
        }
      },
      marginTop: (props) => props.topOffset && props.topOffset + "%",
      width: "100%",
    },
    loader: {
      alignItems: (props) =>
        props.position === "centered" ? "center" : "flex-start",
      backgroundColor: (props) =>
        props.type === "fullscreen" || props.type === "overlay"
          ? alpha(theme.palette.background.paper, 0.75)
          : "",
      bottom: 0,
      display: "flex",
      fontSize: (props) => {
        switch (props.size) {
          case "large":
            return "1.25rem";
          case "medium":
            return "1rem";
          case "small":
            return "0.875rem";
        }
      },
      height: (props) => (props.position === "centered" ? "100%" : "auto"),
      left: 0,
      position: (props) => {
        switch (props.type) {
          case "fullscreen":
            return "fixed";
          case "overlay":
            return "absolute";
          case "inline":
            return "static";
        }
      },
      right: 0,
      top: 0,
      width: "100%",
      zIndex: theme.zIndex.tooltip + 1,
    },
    progress: {
      // color: theme.palette.primary.main,
      // margin: theme.spacing(0, 1, 0, 0),
    },
    subtitle: {
      // color: theme.palette.grey[800],
      // fontSize: props => {
      //   switch (props.size) {
      //     case 'large':
      //       return '0.875rem';
      //     case 'medium':
      //       return '0.75rem';
      //     case 'small':
      //       return '0.625rem';
      //   }
      // },
      // margin: theme.spacing(0.25, 0, 0),
      // textTransform: 'uppercase',
    },
    text: {
      maxWidth: 230, // this is needed for long subtitles (to force that text to wrap)
    },
    title: {
      color: theme.palette.primary.main,
      fontSize: (props) => {
        switch (props.size) {
          case "large":
            return "1.25rem";
          case "medium":
            return "1rem";
          case "small":
            return "0.875rem";
        }
      },
      margin: 0,
    },
  });
});
