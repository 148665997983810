import { useEffect, useState } from "react";
// Components
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material";
import { Loader } from "../components/loader";
import { Page } from "../components/skeleton";
import { ApplicationConstants } from "../constants";
// import { Loader, Page, PageTitle } from '../../components';

const ErrorPage = (): JSX.Element => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = `${ApplicationConstants.CompanyName} - Error`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page title="Error">
      {isLoading && <Loader position="top-center" type="inline" />}
      {!isLoading && <div>Error Page</div>}
    </Page>
  );
};

const useStyles = makeStyles((theme) => ({}));

export default ErrorPage;
