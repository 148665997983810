import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Link } from "react-router-dom";
import { colors } from "../../styles/theme";

const HeroBanner: React.FC = () => {
  const classes = useStyles();
  return (
    <section id="hero" className={classes.heroSection}>
      <h1 className={classes.heroSectionHeader}>
        Welcome to{" "}
        <span className={classes.jaminDetail}>Jamin Detail, LLC</span>
      </h1>
      <p className={classes.heroSectionDescription}>
        Your premier mobile detailing service.
      </p>
      <Link to="/book-now" className={classes.ctaButton}>
        Book Now
      </Link>
    </section>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  // Define your styles here
  heroSection: {
    textAlign: "center",
    // background: `url(${path-to-your-hero-image.webp}) no-repeat center center/cover`,
  },
  heroSectionHeader: {
    fontSize: "2.5rem",
    marginBottom: "20px",
    color: theme.palette.common.black,
  },
  heroSectionDescription: {
    fontSize: "1.2rem",
    marginBottom: "30px",
    color: theme.palette.common.black,
  },
  jaminDetail: {
    color: theme.palette.primary.main,
  },
  ctaButton: {
    padding: "10px 30px",
    fontSize: "1.1rem",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer",
    borderRadius: "5px",
    transition: "background-color 0.3s ease",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export default HeroBanner;
