import { FC, useState, useEffect } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link as MLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// Components
import {
  Button,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
// icons
import { Close, Menu, HomeOutlined } from "@mui/icons-material";

interface IMobileDrawer {}

export const MobileDrawer: FC<IMobileDrawer> = ({}) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer =
    (isOpen: boolean) => (event: KeyboardEvent | MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as KeyboardEvent).key === "Tab" ||
          (event as KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawerOpen(isOpen);
    };

  const isDesktop = useMediaQuery("(min-width: 768px)");

  // close the drawer if the menu gets resized into desktop view
  useEffect(() => {
    if (isDesktop && isDrawerOpen) {
      setDrawerOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  const navigate = useNavigate();

  return (
    <div
    //  className={classes.mobileWrapper}
    >
      <Button
        // className={clsx(classes.menuButton, classes.mobileMenuButton)}
        onClick={() => toggleDrawer(true)}
      >
        <Menu fontSize="large" />
      </Button>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div
        // className={classes.drawerCloseButton}
        >
          <Button
            startIcon={<Close />}
            color="primary"
            onClick={() => toggleDrawer(false)}
          >
            Close
          </Button>
        </div>
        <div
          // className={clsx(classes.drawerList)}
          role="presentation"
          onClick={() => toggleDrawer(false)}
          onKeyDown={() => toggleDrawer(false)}
        >
          <List>
            <Divider />
            <MLink
              to="/"
              // className={clsx(classes.drawerLink)}
            >
              <ListItem button key="home">
                <ListItemIcon
                //  className={classes.listItemIcon}
                >
                  <HomeOutlined color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Home"
                  // className={classes.drawerMobileListItem}
                />
              </ListItem>
            </MLink>
            <Divider />
          </List>
        </div>
      </Drawer>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  // mobileWrapper: {
  //   display: "block",
  //   "@media (min-width: 912px)": {
  //     display: "none",
  //   },
  // },
  // menuButton: {
  //   height: 78,
  //   borderRadius: 0,
  //   boxShadow: "none",
  //   color: theme.palette.background.paper,
  //   fontSize: theme.spacing(1),
  //   padding: theme.spacing(1, 1.5),
  //   fontWeight: 100,
  //   textTransform: "uppercase",
  //   "&:hover": {
  //     backgroundColor: darken(theme.palette.primary.main, 0.3),
  //   },
  //   "&.active": {
  //     backgroundColor: darken(theme.palette.primary.main, 0.3),
  //   },
  //   "@media (min-width: 912px)": {
  //     height: 118,
  //   },
  // },
  // mobileMenuButton: {
  //   backgroundColor: "#262626",
  // },
  // drawerList: {
  //   padding: theme.spacing(0.5),
  //   width: 300,
  // },
  // drawerMobileListItem: {
  //   textTransform: "uppercase",
  //   "&& .MuiListItemText-primary": {
  //     fontSize: theme.spacing(1),
  //     letterSpacing: theme.spacing(0.2),
  //   },
  // },
  // drawerLink: {
  //   color: theme.palette.primary.main,
  //   textDecoration: "none",
  //   "&.active": {
  //     color: darken(theme.palette.primary.main, 0.3),
  //   },
  // },
  // drawerCloseButton: {
  //   display: "flex",
  //   justifyContent: "flex-end",
  //   padding: theme.spacing(0.5),
  // },
  // listItemIcon: {
  //   minWidth: theme.spacing(2),
  // },
}));
