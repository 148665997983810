import { createTheme, lighten, darken, Theme } from "@mui/material/styles";

const baseFontSize = 14; // .875rem

// const { palette } = createTheme();
// const { augmentColor } = palette;
// const createColor = (mainColor: any) =>
//   augmentColor({ color: { main: mainColor } });

export const colors = {
  companyBlue: "#004a99",
  white: "#fff",
  black: "#000",
};

// const { palette } = createTheme();
// const { augmentColor } = palette;
// const createColor = (mainColor: any) =>
//   augmentColor({ color: { main: mainColor } });

const defaultTheme = createTheme({
  spacing: 16, // 1rem
  palette: {
    error: {
      main: "#A94442",
    },
    warning: {
      main: "#8A6D3B",
    },
    info: {
      main: "#35a11b",
    },
    success: {
      main: "#3C763D",
    },
  },
  breakpoints: {
    values: {
      xs: 0, // small devices
      sm: 600, // tablets
      md: 900, // small laptop screens
      lg: 1200, // large screens, adjust this value as needed
      xl: 1536, // extra large screens
    },
  },
  // typography: {
  //   fontFamily: "droid-sans, sans-serif",
  //   fontSize: baseFontSize,
  //   h1: {
  //     fontSize: baseFontSize * 2.25,
  //     fontFamily: "DinPro-Light, sans-serif",
  //   },
  //   h2: {
  //     fontSize: baseFontSize * 2,
  //     fontFamily: "DinPro-Light, sans-serif",
  //   },
  //   h3: {
  //     fontSize: baseFontSize * 1.75,
  //     fontFamily: "DinPro-Light, sans-serif",
  //   },
  //   h4: {
  //     fontSize: baseFontSize * 1.5,
  //     fontFamily: "DinPro-Light, sans-serif",
  //   },
  //   h5: {
  //     fontSize: baseFontSize * 1.25,
  //     fontFamily: "DinPro-Light, sans-serif",
  //   },
  // },
});

// Break this out so that we can reference palette and other options
defaultTheme.components = {
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        borderRadius: 0,
      },
    },
  },
  MuiPaper: {
    defaultProps: {
      square: true,
      elevation: 1,
    },
  },
  MuiAccordion: {
    defaultProps: {
      square: true,
    },
  },
  MuiRadio: {
    defaultProps: {
      color: "primary",
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        borderRadius: 0,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        lineHeight: 1.25,
      },
    },
  },
};

export const lightTheme = createTheme(defaultTheme, {
  palette: {
    mode: "light",
    common: {
      black: colors.black,
      white: colors.white,
    },
    primary: {
      light: lighten(colors.companyBlue, 0.1),
      main: colors.companyBlue,
      dark: darken(colors.companyBlue, 0.4),
      contrastText: colors.white,
    },
    secondary: {
      light: lighten(colors.white, 0.1),
      main: colors.companyBlue,
      dark: darken(colors.white, 0.4),
      contrastText: colors.white,
    },

    background: {
      default: colors.white,
    },
  },
});

export const darkTheme = createTheme(defaultTheme, {
  spacing: 16, // 1rem
  palette: {
    mode: "dark",
    common: {
      black: colors.white,
      white: colors.black,
    },
    primary: {
      light: lighten(colors.companyBlue, 0.1),
      main: colors.companyBlue,
      dark: darken(colors.companyBlue, 0.4),
      contrastText: colors.black,
    },
    secondary: {
      light: darken(colors.companyBlue, 0.1),
      main: "#222",
      dark: lighten(colors.companyBlue, 0.4),
      contrastText: colors.companyBlue,
    },
  },
});
