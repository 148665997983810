import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const images: ReactImageGalleryItem[] = [
  {
    original: "../../images/gallery/20220210_182343.webp",
    thumbnail: "../../images/gallery/20220210_182343-thumb.webp",
  },
];

const GalleryComponent: React.FC<{
  items?: ReactImageGalleryItem[];
}> = ({ items }) => {
  const classes = useStyles();

  return (
    <div className={classes.imageGalleryContainer}>
      <ImageGallery
        items={items ?? images}
        autoPlay={true}
        thumbnailPosition="bottom"
        infinite={true}
        showNav={true}
        showThumbnails={true}
        // showBullets={true}
        // showIndex={true}
      />
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  imageGalleryContainer: {
    "& .image-gallery-content:not(.fullscreen) .image-gallery-slide .image-gallery-image":
      {
        width: "100%",
        height: "400px",
      },
    "& .image-gallery-content:not(.fullscreen) .image-gallery-thumbnails-container .image-gallery-thumbnail-image":
      {
        maxHeight: "75%",
        maxWidth: "75%",
      },
  },
}));

export default GalleryComponent;
