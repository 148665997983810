import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.wrapper}>
      <p>
        <Typography variant="caption">
          *Prices subject to change based on vehicle & condition
        </Typography>
      </p>
      <p>
        <Typography variant="caption" className={classes.copyRight}>
          &copy; {new Date().getFullYear()} Jamin Detail. All rights reserved.
        </Typography>
      </p>
      <p>
        <Typography variant="caption">
          <Link to="/privacy-policy">Privacy Policy</Link>
        </Typography>
      </p>
    </footer>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#333",
    color: "#fff",
    textAlign: "center",
    padding: "10px 0",
  },
  copyRight: {
    // // color: theme.palette.grey[600],
    // // paddingBottom: 10,
    // display: "block",
    // textAlign: "center",
    // "@media (min-width: 600px)": {
    //   paddingBottom: 0,
    //   textAlign: "baseline",
    // },
  },
}));

// TODO: Implement
// body.dark-mode footer {
//   background-color: #222; /* Even darker shade for header/footer */
// }
