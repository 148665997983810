import React, { FC } from "react";
import clsx from "clsx";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
// images
import LogoPrimary from "../../images/logo/logo-dark.webp";
import LogoSecondary from "../../images/logo/logo-blue.webp";

interface ILogoProps {
  className?: string;
  isSmall?: boolean;
  isPrimary?: boolean;
}

export const Logo: FC<ILogoProps> = ({
  className,
  isSmall = false,
  isPrimary = true,
}) => {
  const classes = useStyles({ isSmall });
  return (
    <img
      src={isPrimary ? LogoPrimary : LogoSecondary}
      alt="logo"
      className={clsx(classes.logo, className)}
    />
  );
};

const useStyles = makeStyles<Theme, { isSmall?: boolean }>((theme: Theme) => ({
  logo: ({ isSmall }) =>
    isSmall
      ? {
          width: 115,
          paddingBottom: 15,
          height: "auto",
          "@media (min-width: 910px)": {
            width: 150,
          },
        }
      : {
          width: 175,
          paddingBottom: 15,
          height: "auto",
          "@media (min-width: 910px)": {
            width: 250,
          },
        },
}));
