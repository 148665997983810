import { FC } from "react";
import makeStyles from "@mui/styles/makeStyles";
// Components
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Grid, Theme } from "@mui/material";

interface IPage {
  children: any;
  title?: string;
}

export const Page: FC<IPage> = ({ children, title }) => {
  const classes = useStyles();
  // const siteName = "Jamin Detail";
  // const renderTitle = title ? `${title} | ${siteName}` : siteName;

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.content}>
        <Header />
      </Grid>
      <Grid item xs={12} className={classes.content}>
        {children}
      </Grid>
      <Grid item xs={12} className={classes.content}>
        <Footer />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  "@global": {
    "html, body": {
      paddingTop: 25, // px by default
      height: "100%",
      width: "100%",
      margin: 0,
    },
    body: {
      fontFamily: "Arial, sans-serif",
      lineHeight: 1.6,
      backgroundColor: theme.palette.background.default,
      display: "flex",
      flexDirection: "column",
      minHeight: "calc(100vh - 75px)", // Using calc() here to subtract header height
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flexGrow: 1, // takes up all available space after header
    overflow: "hidden", // no global scrollbar
    height: "100%",
  },
  // TODO: Still needed?
  //   .container,
  // .section {
  //   max-width: 2200px; /* Increase this value as needed */
  //   margin: 0 auto; /* Keeps the content centered */
  //   padding: 20px; /* Adjust padding as needed */
  // }
}));

// TODO: Implement
// /* Dark Mode Adjustments */
// body.dark-mode .about-section {
//   background-color: #333; /* Darker background for contrast in dark mode */
// }
// /* Dark Mode Specific Styles */
// body.dark-mode {
//   background-color: #121212; /* Dark background */
//   color: #e0e0e0; /* Lighter text for readability in dark mode */
// }
