import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/homePage";
import BookNowPage from "./pages/bookNowPage";
import PricingPage from "./pages/pricingPage";
import ErrorPage from "./pages/errorPage";
import PrivacyPolicyPage from "./pages/privacyPolicyPage";
import { ContactPage } from "@mui/icons-material";
import ScrollToHash from "./components/scrollToHash/scrollToHash";
import GalleryPage from "./pages/galleryPage";

const App: React.FC = () => (
  <BrowserRouter>
    <ScrollToHash />
    <Routes>
      // Current Routes
      <Route path="/" element={<HomePage />} />
      <Route path="/book-now" element={<BookNowPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/gallery" element={<GalleryPage />} />
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      // Legacy Routes - remove after google search console has updated
      <Route path="/book-now.html" element={<BookNowPage />} />
      <Route path="/contact.html" element={<ContactPage />} />
      <Route path="/error.html" element={<ErrorPage />} />
      <Route path="/index.html" element={<HomePage />} />
      <Route path="/pricing.html" element={<PricingPage />} />
      <Route path="/privacy-policy.html" element={<PrivacyPolicyPage />} />
      {/* Fallback route for unmatched paths */}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  </BrowserRouter>
);

export default App;
