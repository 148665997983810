import { FC, useContext, useState } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faMoon } from "@fortawesome/free-solid-svg-icons";
import { ThemeContext } from "../..";

export const Header: FC = () => {
  const classes = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const { mode, toggleTheme } = useContext(ThemeContext);

  const logoSrc =
    mode === "light"
      ? "/images/logo/logo-dark.webp"
      : "/images/logo/logo-blue.webp";

  return (
    <header className={classes.header}>
      <Link to="/#" className={classes.headerLogo}>
        <img
          id="siteLogo"
          src={logoSrc}
          alt="Jamin Detail Logo"
          style={{ maxHeight: "75px" }}
        />
      </Link>
      <button className={classes.hamburger} onClick={toggleMenu}>
        &#9776; {/* Hamburger icon */}
      </button>
      <div
        className={
          classes.navContainer +
          ` ${isMenuOpen ? classes.activeNavContainer : ""}`
        }
      >
        <nav>
          <ul className={classes.navLinks}>
            <li>
              <Link to="/#" className={classes.navLink}>
                <strong>Services</strong>
              </Link>
            </li>
            <li>
              <Link to="/book-now" className={classes.navLink}>
                <strong>Booking</strong>
              </Link>
            </li>
            <li>
              <Link to="/pricing" className={classes.navLink}>
                <strong>Pricing</strong>
              </Link>
            </li>
            <li>
              <Link to="/#about" className={classes.navLink}>
                <strong>About</strong>
              </Link>
            </li>
          </ul>
          <div className={classes.contactInfo}>
            <span className={classes.phoneNumber}>
              <a href="tel:+13525140964" className={classes.navLink}>
                <strong>(352) 514-0964</strong>
              </a>
            </span>
            <a
              href="mailto:jamindetail22@gmail.com?subject=Booking%20Information%20Request"
              className={classes.navIconLink}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100086496340257"
              target="_blank"
              className={classes.navIconLink}
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href="https://maps.app.goo.gl/jtSY79Ggt8nAvqx57"
              target="_blank"
              className={classes.navIconLink}
            >
              <FontAwesomeIcon icon={faGoogle} />
            </a>
            <div className={classes.themeToggle} style={{ display: "none" }}>
              <button id="theme-toggle-btn" onClick={toggleTheme}>
                <FontAwesomeIcon icon={faMoon} />
              </button>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  header: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    display: "flex",
    justifyContent: "space-between",
    minHeight: 70,
    padding: "0px 20px",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 1000,
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
  },
  navLinks: {
    width: "100%" /* Full width */,
    display: "flex",
    alignItems: "center",
    "& li": {
      textAlign: "center",
      width: "100%" /* Full width */,
    },
  },

  navContainer: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    "& ul": {
      display: "flex",
      width: "100%" /* Full width */,
      justifyContent: "center",
      margin: 0,
      padding: 0,
      listStyle: "none",

      [theme.breakpoints.up("md")]: {
        transform: "translateY(50%)",
      },
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    "& ul li a": {
      color: theme.palette.secondary.contrastText,
      textDecoration: "none",
      fontSize: "1.3rem",
    },

    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("md")]: {
      display: "none", // Hide by default
      position: "absolute",
      top: "100%", // Positioned directly below the header
      left: 0,
      width: "100%", // Take up full width
      flexDirection: "column", // Stack items vertically
      zIndex: 2, // Ensure it's above other content
    },
  },
  activeNavContainer: {
    [theme.breakpoints.down("md")]: {
      display: "flex", // Show the container
      flexDirection: "column", // Stack items vertically
      paddingRight: "20px",
      alignItems: "flex-end", // Center items vertically}
    },
  },
  headerLogo: {
    position: "absolute",
    left: 20,
    top: "50%",
    transform: "translateY(-50%)",
    marginRight: "auto",
    paddingTop: 10,
  },
  appBarGrid: {
    height: "100%",
    paddingLeft: theme.spacing(0.5),
    "@media (min-width: 912px)": {
      paddingLeft: 0,
    },
  },
  mobileDrawer: {
    alignItems: "left",
  },
  divider: {
    backgroundColor: theme.palette.grey[700],
  },
  hamburger: {
    display: "none",
    fontSize: 24,
    cursor: "pointer",

    [theme.breakpoints.down("md")]: {
      // This targets screen sizes less than or equal to large (1200px by default)
      display: "block", // Show hamburger icon
      position: "absolute", // Position it independently of other items
      right: "20px", // Distance from the right edge of the header
      top: "50%", // Center it vertically
      transform: "translateY(-50%)", // Offset by half its height to truly center it
    },
  },
  themeToggle: {
    "& button": {
      background: "none",
      border: "none",
      color: theme.palette.secondary.contrastText,
      cursor: "pointer",
      fontSize: 24,
    },
    "& button:hover": {
      color: "#ddd",
    },
  },
  navLink: {
    color: theme.palette.secondary.contrastText,
    textDecoration: "none",
    "&:hover": {
      color: "#ddd",
    },
    margin: "15px",
    fontSize: "1.3rem",
  },
  navIconLink: {
    color: theme.palette.secondary.contrastText,
    fontSize: 24,
    cursor: "pointer",
    "&:hover": {
      color: "#ddd",
    },
    // border: "none",
    margin: "0px 10px" /* Adds spacing between items */,
  },

  contactInfo: {
    top: "50%",
    display: "flex",
    alignItems: "center",
    textDecoration: "none" /* Removes underline from links */,
    "&:hover": {
      color: "#ddd",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      right: "20px",
      transform: "translateY(-50%)",
    },
  },

  phoneNumber: {
    color: "inherit",
    textDecoration: "none",
    "& a": {
      color: "inherit",
      textDecoration: "none",
    },
  },

  // TODO: Implement

  //   .contact-info {
  //     position: static; /* Resets to default positioning */
  //     transform: translateY(0%); /* Resets Centers vertically */
  //     display: flex; /* Make the contact-info a flex container */
  //     flex-direction: column; /* Stack the items vertically */
  //     align-items: center; /* Center the items horizontally */
  //     margin-top: 20px; /* Add some space above the contact info */
  //   }

  //   .contact-info > * {
  //     margin-bottom: 10px; /* Add some space between contact info items */
  //   }
  // }
}));
