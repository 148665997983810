import { useEffect, useState } from "react";
// Components
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material";
import { Loader } from "../components/loader";
import { Page } from "../components/skeleton";
import { ApplicationConstants } from "../constants";
// import { Loader, Page, PageTitle } from '../../components';

const PrivacyPolicyPage = (): JSX.Element => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = `${ApplicationConstants.CompanyName} - Privacy Policy`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page title="Privacy Policy">
      {isLoading && <Loader position="top-center" type="inline" />}
      {!isLoading && (
        <main>
          <section id="privacy-policy" className={classes.privacyPolicy}>
            <h1>Privacy Policy</h1>
            <p>
              Last Updated: <span>2024-04-01</span>.
            </p>

            <p>
              This Privacy Policy (“Policy”) outlines the information
              collection, use, and sharing practices of Jamin Detail, LLC (“we,”
              “us,” and “our”).
            </p>

            <p>
              This Policy applies to your interaction with us through our
              website (jamindetail.com) and any services (“Services”) we offer
              or host.
            </p>

            <p>
              By interacting with our Services, you consent to the collection,
              use, and disclosure of your information as described in this
              Policy. If you disagree with any part of this Policy, please
              refrain from using our Services.
            </p>

            <p>
              If you do not agree with this Policy, we ask that you not use our
              Services.
            </p>

            <h2>Principles Guiding Our Data Practices</h2>

            <p>
              At Jamin Detail, LLC, our privacy practices are built around the
              following principles:
            </p>
            <ul>
              <li>Transparency and accessibility in our privacy policies.</li>
              <li>
                Streamlining data practices to enhance security and user
                comprehension.
              </li>
              <li>Aligning our data handling with user expectations.</li>
            </ul>

            <h2>Types of Information We Collect</h2>

            <p>
              Our information collection can be direct, passive, or from
              third-party sources.
            </p>

            <h3>Information You Provide</h3>

            <p>
              Any information you provide directly to us is collected. This may
              include details shared when creating an account, making a
              purchase, contacting us, or subscribing to newsletters, among
              others. Information collected may cover names, contact details,
              payment information, and your geographical location.
            </p>

            <h3>Automatically Collected Information</h3>
            <p>
              <i>Device and Usage Data</i>
            </p>
            <p>
              We may automatically gather information about your devices and
              interaction with our Services. This includes device identifiers,
              location data, browsing patterns, and detailed engagement metrics
              with our Services, which help us understand user behavior and
              improve our offerings.
            </p>

            <p>
              <i>Cookies and Tracking Technologies</i>
            </p>
            <p>
              Our Services utilize cookies, server logs, and other tracking
              technologies to collect data about your usage. These tools help us
              recognize your device, tailor your user experience, conduct
              analytics, and support our security measures. You have the option
              to control cookie settings through your browser, though this may
              affect your access to certain Service features.
            </p>

            <h3>Third-Party Information</h3>
            <p>
              We may enhance our records with information obtained from
              third-party sources, which can include public databases, social
              media platforms, and marketing firms. This data can range from
              contact details to demographic information, subject to legal
              constraints.
            </p>

            <h2>Using Your Information</h2>
            <p>
              We utilize the collected information to deliver and enhance our
              Services, fulfill user requests, conduct analytics, respond to
              inquiries, and for legal compliance and protection purposes. We
              strive to combine and use this information in alignment with this
              Policy.
            </p>

            <h2>Disclosure of Your Information</h2>
            <p>
              Information may be shared with service providers, legal entities,
              or as part of business transactions. Our goal is to protect user
              data while complying with legal obligations and ensuring the
              security of our Services.
            </p>

            <h2>Legal Basis for Processing</h2>
            <p>
              We process personal data based on contractual necessities,
              legitimate interests, legal obligations, or your consent. Our
              operations are designed to respect user rights and comply with
              relevant legal standards.
            </p>

            <h2>Online Analytics and User Choices</h2>
            <p>
              We employ third-party analytics to understand Service usage and
              facilitate user preference management, including opt-out options
              for email communications and account settings adjustments.
            </p>

            <h2>International Data Transfers</h2>
            <p>
              Your information may be processed outside of your home country,
              and we are committed to safeguarding your data in line with this
              Policy, regardless of where it is processed.
            </p>

            <h2>Data Security and Retention</h2>
            <p>
              We have implemented security measures to protect your data, though
              no system is infallible. Data is retained in accordance with legal
              requirements and the purposes outlined in this Policy.
            </p>

            <h2>Children's Privacy</h2>
            <p>
              Our Services are not intended for individuals under the age of 18.
              We do not knowingly collect data from children under 13 (or under
              16 in certain jurisdictions) and will take steps to delete such
              information if we become aware of it.
            </p>

            <h2>Third-Party Links</h2>
            <p>
              Our Services may include links to external sites or services,
              which are not covered by this Policy. We encourage you to review
              the privacy policies of any third-party sites you visit.
            </p>

            <h2>Policy Updates</h2>
            <p>
              This Policy may be updated to reflect changes to our practices or
              regulatory requirements. We will post updates on our website and
              encourage you to review this Policy periodically.
            </p>

            <h2>Contacting Us</h2>
            <p>
              If you have any questions about this Policy or our data practices,
              please contact us via email.
            </p>
          </section>
        </main>
      )}
    </Page>
  );
};

const useStyles = makeStyles((theme) => ({
  privacyPolicy: {
    background: "#f4f4f4",
    padding: 20,
    borderRadius: 8,
    margin: "20px 0",
    "& h2": {
      color: "#004a99",
      fontSize: "1.8rem",
      marginBottom: 15,
    },
    "& h3": {
      color: "#004a99",
      fontSize: "1.5rem",
      marginTop: 20,
      marginBottom: 10,
    },
    "& p": {
      color: "#333",
      fontSize: "1rem",
      lineHeight: 1.6,
      marginBottom: 10,
    },
    "& ul": {
      listStyleType: "disc",
      paddingLeft: 20,
    },
    "& li": {
      marginBottom: 5,
    },
    "& span": {
      color: "#333",
    },
    "& i": {
      color: "#666",
    },
    "& .last-updated": {
      fontStyle: "italic",
      color: "#666",
      fontSize: "0.9rem",
      display: "block",
      textAlign: "right",
      marginTop: 20,
    },
  },

  // /* Dark Mode Styles for Privacy Policy Section */
  // body.dark-mode .privacy-policy {
  //   background-color: #333; /* Darker background for the section */
  //   color: #f4f4f4; /* Light text for better readability */
  // }
  // body.dark-mode #privacy-policy h2,
  // body.dark-mode #privacy-policy h3 {
  //   color: #0096ff; /* Bright blue for headings to stand out in dark mode */
  // }
  // body.dark-mode #privacy-policy p,
  // body.dark-mode #privacy-policy ul,
  // body.dark-mode #privacy-policy li {
  //   color: #ddd; /* Slightly dimmed text for normal text and list items */
  // }
  // body.dark-mode #privacy-policy span,
  // body.dark-mode #privacy-policy i {
  //   color: #bbb; /* Slightly lighter than normal text for emphasis or italics */
  // }
  // body.dark-mode #privacy-policy .last-updated {
  //   color: #aaa; /* Even lighter color for less important text like 'last updated' */
  // }
}));

export default PrivacyPolicyPage;
