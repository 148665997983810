import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToHash() {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      scrollToElement(hash.slice(1));
    } else {
      scrollToTop();
    }
  }, [hash]);

  function scrollToElement(elementName: string) {
    setTimeout(() => {
      const element = document.getElementById(elementName);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100); // Delay in milliseconds
  }

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return null;
}

export default ScrollToHash;
